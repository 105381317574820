export const emits = {
  CANCEL: "cancel"
};

export const dateFormats = {
  YYYYMMDD: "YYYY-MM-DD",
  DDMMYYYY: "dd/mm/yy",
  DD: "DD"
};

export const typeNotification = {
  INFO: "info",
  ERROR: "error",
  SUCCESS: "success",
  WARN: "warn",
};

export const actions = {
  UPDATE: "update",
  SAVE: "save",
};

export const employeeAccess = {
  EMPLOYEE_CREATE: "empleados_create",
  EMPLOYEE_UPDATE: "empleados_update",
};

export const chargeAccess = {
  CHARGE_READ: "cargos_read",
  CHARGE_CREATE: "cargos_create",
  CHARGE_UPDATE: "cargos_update",
  CHARGE_DELETE: "cargos_delete",
};

export const dispatchType = {
  BREAD_SET_ITEMS: "bread/setItems",
};

export const mapGettersType = {
  BREAD: "bread",
  BREADCRUMB: "breadcrumb",
  AUTH: "auth",
  IS_ADMIN: "isAdmin",
};

export const breadcrumbRoutes = {
  EMPLOYEE_CRUD: [
    { label: "Administración", url: "javascript:void(0);" },
    { label: "Registro de empleados", url: "javascript:void(0);" },
  ],
};

export const coreRoutes = {
  EMPLOYEE_LIST: { name: "employeeList" },
};

export const apiRoutes = {
  payments: "payments",
  payments_types: "payments/types",
  employees: "employees",
  employees_select: "employees/select?q=",
  countries: "countries",
};

export const notificationMessages = {
  required_fields: "Campos requeridos",
  check_information: "Por favor verifique la información",
  record_saved: "Registro guardado",
  SAVED_SUCCESSFULLY: "Se guardó correctamente",
  record_deleted: "Registro eliminado",
  DELETED_SUCCESSFULLY: "Se eliminó correctamente",
  error: "Error",
  THERE_WAS_AN_ERROR: "se a producido un error",
  ATTENTION: "Atención",
  not_selected: "no seleccionado",
  please_select: "Por favor seleccione",
};

export const yearRangeDateInitMyEnrollment = "2018:2050";
export const yearRangeDateFinishMyEnrollment = "2020:2050";
export const yearRangeInitialPaymentDateEnrollment = "2020:2050";
export const yearRangeFinishPaymentDateEnrollment = "2020:2050";
export const typeEnrollmentForClassCss = {
  optional: { name: "Opcional", classCss: "typeA" },
  optionalByNovelty: { name: "Opcional por Novedad", classCss: "typeB" },
  firstInstallment: { name: "Primera Cuota", classCss: "typeC" },
  mandatory: { name: "Obligatoria", classCss: "typeD" },
  advance: { name: "Anticipada", classCss: "typeJ" },
  colorPayActually: { name: "N/A", classCss: "typeE" },
  colorDebt: { classCss: "typeF" },
  havePaymentFeeMajor: { classCss: "typeG" },
  lastTypePaymentRb: { classCss: "typeH" },
  payActually: { name: "N/A", classCss: "typeI" }
};
export const initialEnrollmentColorPoints = 1;

export const myEnrollmentsReport = "XLS";

export const typePayment = {
  INSCRIPTION: {
    name: "INSCRIPTION",
    label: "Inscripción"
  },
  FEE: {
    name: "FEE",
    label: "Cuota"
  }
};

export const numberFormat = { EN_US: "en-US" };

export const paymentDetailStates = {
  ALl: "Todos",
  PENDING: "Pendiente",
  APPROVED: "Aprobado",
  DENY: "Denegado",
  CHECK: "cheque"
};

export const componentsNames = {
  CHARGE: "Charge",
};

export const fileUploads = {
  MAX_FILE_SIZE: 5000000,
  FILE_NUMBER_ALLOW: 5
};
