<template>
  <div v-if="canAccess('empleados_create') || canAccess('empleados_update')">
    <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items" />
    <Fieldset legend="Datos principales" :toggleable="true">
      <div class="p-grid">
        <div class="p-field p-fluid p-col-12">
          <ProgressBar
              mode="indeterminate"
              v-if="loading"
              class="p-progressbar"
          />
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-xl-3">
          <span class="p-float-label">
            <InputText
                id="ced"
                type="text"
                v-model="employee.dni"
                :class="{ 'p-invalid': submitted && !employee.dni }"
            />
            <label class="p-label" for="ced">Cédula</label>
          </span>
          <small class="p-invalid" v-if="submitted && !employee.dni">
            La cédula es requerida
          </small>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-xl-3">
          <span class="p-float-label">
            <InputText
                id="nomb"
                type="text"
                v-model="employee.name"
                :class="{ 'p-invalid': submitted && !employee.name }"
            />
            <label class="p-label" for="nomb">Nombres</label>
          </span>
          <small class="p-invalid" v-if="submitted && !employee.name">
            El nombre es requerido
          </small>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-xl-3">
          <span class="p-float-label">
            <InputText
                id="mail"
                type="text"
                v-model="employee.email"
                :class="{ 'p-invalid': submitted && !employee.email }"
            />
            <label class="p-label" for="mail">Email</label>
          </span>
          <small class="p-invalid" v-if="submitted && !employee.email">
            El email es requerido
          </small>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-xl-3">
          <span class="p-float-label">
            <InputText
                id="cont"
                type="text"
                v-model="employee.accounting_code"
            />
            <label class="p-label" for="cont">Código de contabilidad</label>
          </span>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-xl-3">
          <span class="p-float-label width-auto-complete">
            <AutoComplete
                id="car"
                :dropdown="true"
                :multiple="false"
                field="description"
                v-model="employee.position"
                :suggestions="positions"
                @complete="getCatalog($event, 'positions', 'description')"
                :class="{ 'p-invalid': submitted && !employee.position }"
            >
              <template #item="slotProps">
                <div class="autocomplete-value">
                  {{ slotProps.item.description }}
                </div>
              </template>
            </AutoComplete>
            <label class="p-label" for="car">Cargo</label>
          </span>
          <small class="p-invalid" v-if="submitted && !employee.position">
            El cargo es requerido
          </small>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-xl-3">
          <span class="p-float-label width-auto-complete">
            <AutoComplete
                id="jef"
                :dropdown="true"
                :multiple="false"
                field="name"
                v-model="employee.boss"
                :suggestions="employees"
                @complete="getEmployeeFilter($event)"
            >
              <template #item="slotProps">
                <div class="autocomplete-value">
                  {{ slotProps.item.name }}
                </div>
              </template>
            </AutoComplete>
            <label class="p-label" for="jef">Jefe</label>
          </span>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-xl-3">
          <span class="p-float-label">
            <Calendar
                id="calendar"
                :showIcon="true"
                v-model="employee.date_admission"
                :class="{ 'p-invalid': submitted && !employee.date_admission }"
                dateFormat="dd/mm/yy"
                :yearNavigator="true"
                yearRange="1990:2050"
                appendTo="body"
            />
            <label class="p-label" for="calendar">Fecha de ingreso</label>
          </span>
          <small class="p-invalid" v-if="submitted && !employee.date_admission">
            La fecha de ingreso es requerida
          </small>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-xl-3">
          <span class="p-float-label">
            <Calendar
                id="calendar1"
                :showIcon="true"
                v-model="employee.promotion_date"
                dateFormat="dd/mm/yy"
                :yearNavigator="true"
                yearRange="1980:2050"
            />
            <label class="p-label" for="calendar1">Fecha de ascenso</label>
          </span>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-xl-3">
          <span class="p-float-label width-auto-complete">
            <AutoComplete
                id="country"
                :dropdown="true"
                :multiple="false"
                field="name"
                v-model="employee.country"
                :suggestions="countries"
                @complete="getCatalog($event, 'countries', 'name')"
                :class="{ 'p-invalid': submitted && !employee.country }"
            >
              <template #item="slotProps">
                <div class="autocomplete-value">
                  {{ slotProps.item.name }}
                </div>
              </template>
            </AutoComplete>
            <label class="p-label" for="car">Pais</label>
          </span>
          <small class="p-invalid" v-if="submitted && !employee.country">
            El país es requerido
          </small>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-xl-3">
          <span class="p-float-label width-auto-complete">
            <AutoComplete
                id="comp"
                :dropdown="true"
                :multiple="false"
                field="description"
                v-model="employee.company"
                :suggestions="companies"
                @complete="getCatalog($event, 'companies', 'description', 1)"
                :class="{ 'p-invalid': submitted && !employee.company }"
            >
              <template #item="slotProps">
                <div class="autocomplete-value">
                  {{ slotProps.item.description }}
                </div>
              </template>
            </AutoComplete>
            <label class="p-label" for="comp">Empresa</label>
          </span>
          <small class="p-invalid" v-if="submitted && !employee.company">
            La empresa es requerida
          </small>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-xl-3">
          <span class="p-float-label width-auto-complete">
            <AutoComplete
                id="fil"
                :dropdown="true"
                :multiple="false"
                field="name"
                v-model="employee.subsidiary"
                :suggestions="subsidiaries"
                @complete="getCatalog($event, 'subsidiaries', 'name')"
                :class="{ 'p-invalid': submitted && !employee.subsidiary }"
            >
              <template #item="slotProps">
                <div class="autocomplete-value">
                  {{ slotProps.item.name }}
                </div>
              </template>
            </AutoComplete>
            <label class="p-label" for="fil">Filial</label>
          </span>
          <small class="p-invalid" v-if="submitted && !employee.subsidiary">
            El filial es requerido
          </small>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-xl-3">
          <span class="p-float-label width-auto-complete">
            <AutoComplete
                id="org"
                :dropdown="true"
                :multiple="false"
                field="description"
                v-model="employee.organization"
                :suggestions="organizations"
                @complete="getCatalog($event, 'organizations', 'description')"
                :class="{ 'p-invalid': submitted && !employee.organization }"
            >
              <template #item="slotProps">
                <div class="autocomplete-value">
                  {{ slotProps.item.description }}
                </div>
              </template>
            </AutoComplete>
            <label class="p-label" for="org">Organización</label>
          </span>
          <small class="p-invalid" v-if="submitted && !employee.organization">
            La organization es requerida
          </small>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-xl-3">
          <span class="p-float-label width-auto-complete">
            <AutoComplete
                id="pag"
                :dropdown="true"
                :multiple="false"
                field="description"
                v-model="employee.payment_type"
                :suggestions="payments"
                @complete="getCatalog($event, 'payments', 'description', 1)"
                :class="{ 'p-invalid': submitted && !employee.payment_type }"
            >
              <template #item="slotProps">
                <div class="autocomplete-value">
                  {{ slotProps.item.description }}
                </div>
              </template>
            </AutoComplete>
            <label class="p-label" for="pag">Forma de pago</label>
          </span>
          <small class="p-invalid" v-if="submitted && !employee.payment_type">
            La forma de pago es requerida
          </small>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-xl-3">
          <span class="p-float-label">
            <InputText id="cta" type="text" v-model="employee.account_code" />
            <label class="p-label" for="cta">Cuenta bancaria</label>
          </span>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-xl-3">
          <span class="p-float-label width-auto-complete">
            <AutoComplete
                id="pens"
                :dropdown="true"
                :multiple="false"
                field="name"
                v-model="employee.pension"
                :suggestions="pensions"
                @complete="getCatalog($event, 'pensions', 'name', 1)"
            >
              <template #item="slotProps">
                <div class="autocomplete-value">
                  {{ slotProps.item.name }}
                </div>
              </template>
            </AutoComplete>
            <label class="p-label" for="org">Pensión</label>
          </span>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-xl-3">
          <span class="p-float-label width-auto-complete">
            <AutoComplete
                id="hal"
                :dropdown="true"
                :multiple="false"
                field="name"
                v-model="employee.health_insurance"
                :suggestions="pensions"
                @complete="getCatalog($event, 'pensions', 'name', 1)"
            >
              <template #item="slotProps">
                <div class="autocomplete-value">
                  {{ slotProps.item.name }}
                </div>
              </template>
            </AutoComplete>
            <label class="p-label" for="hal">Salud</label>
          </span>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-xl-3">
          <div class="p-field p-fluid p-grid p-col-12">
            <Checkbox
                id="binary"
                v-model="employee.inactive"
                :binary="true"
                class="p-mr-2"
            />
            <label for="binary">Inactivo</label>
          </div>
        </div>
      </div>
    </Fieldset>
    <Fieldset legend="Datos generales" :toggleable="true">
      <div class="p-grid">
        <div class="p-field p-fluid p-col-12">
          <ProgressBar
              mode="indeterminate"
              v-if="loading"
              class="p-progressbar"
          />
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-xl-3">
          <span class="p-float-label">
            <Calendar
                id="trans"
                :showIcon="true"
                v-model="employee.transfer_date"
                dateFormat="dd/mm/yy"
                :yearNavigator="true"
                yearRange="1980:2050"
            />
            <label class="p-label" for="trans">Fecha de traslado</label>
          </span>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-xl-3">
          <span class="p-float-label">
            <AutoComplete
                id="filo"
                :dropdown="true"
                :multiple="false"
                field="name"
                v-model="employee.subsidiary_transferred"
                :suggestions="subsidiaries"
                @complete="getCatalog($event, 'subsidiaries', 'name')"
            />
            <label class="p-label" for="filo">Filial de translado</label>
          </span>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-xl-3">
          <span class="p-float-label">
            <Calendar
                id="sal"
                :showIcon="true"
                v-model="employee.health_date"
                dateFormat="dd/mm/yy"
                :yearNavigator="true"
                yearRange="1980:2050"
            />
            <label class="p-label" for="sal">
              Fecha de afiliación de salud
            </label>
          </span>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-xl-3">
          <span class="p-float-label">
            <Calendar
                id="pensd"
                :showIcon="true"
                v-model="employee.pension_date"
                dateFormat="dd/mm/yy"
                :yearNavigator="true"
                yearRange="1980:2050"
            />
            <label class="p-label" for="pensd">
              Fecha de afiliación de pension
            </label>
          </span>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-xl-3">
          <span class="p-float-label">
            <Calendar
                id="box"
                :showIcon="true"
                v-model="employee.box_date"
                dateFormat="dd/mm/yy"
                :yearNavigator="true"
                yearRange="1980:2050"
            />
            <label class="p-label" for="box">
              Fecha de afiliación de caja de comp.
            </label>
          </span>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-xl-3">
          <span class="p-float-label">
            <Calendar
                id="ret"
                :showIcon="true"
                v-model="employee.retirement_date"
                dateFormat="dd/mm/yy"
                :yearNavigator="true"
                yearRange="1980:2050"
            />
            <label class="p-label" for="ret">Fecha de retiro</label>
          </span>
        </div>
      </div>
    </Fieldset>
    <Fieldset legend="Datos domiciliarios y de contacto" :toggleable="true">
      <div class="p-grid">
        <div class="p-field p-fluid p-col-12">
          <ProgressBar
              mode="indeterminate"
              v-if="loading"
              class="p-progressbar"
          />
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-xl-3">
          <span class="p-float-label">
            <AutoComplete
                id="city"
                :dropdown="true"
                :multiple="false"
                field="name"
                v-model="employeeInformation.city"
                :suggestions="cities"
                @complete="getCatalog($event, 'cities', 'name', 1)"
                :class="{ 'p-invalid': submitted && !employeeInformation.city }"
            />
            <label class="p-label" for="city">Ciudad de domicilio</label>
          </span>
          <small class="p-invalid" v-if="submitted && !employeeInformation.city">
            La ciudad de domicilio es requerida
          </small>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-xl-3">
          <span class="p-float-label">
            <InputText
                id="street"
                type="text"
                v-model="employeeInformation.street"
            />
            <label class="p-label" for="street">Dirección (Calles)</label>
          </span>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-xl-3">
          <span class="p-float-label">
            <InputText
                id="zip"
                type="text"
                v-model="employeeInformation.zip_code"
            />
            <label class="p-label" for="zip">Codigo postal</label>
          </span>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-xl-3">
          <span class="p-float-label">
            <InputText
                id="phone"
                type="text"
                v-model="employeeInformation.phone_number"
            />
            <label class="p-label" for="phone">Número de teléfono</label>
          </span>
        </div>
      </div>
    </Fieldset>
    <Fieldset legend="Conceptos fijos" :toggleable="true">
      <div class="p-grid p-dir-col">
        <div class="p-field p-fluid p-col-12">
          <ProgressBar
              mode="indeterminate"
              v-if="loading"
              class="p-progressbar"
          />
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-xl-3">
          <span class="p-float-label">
            <InputText id="link_cv" type="text" v-model="employee.link_cv" />
            <label class="p-label" for="link_cv">Link hoja de vida</label>
          </span>
          <a :href="employee.link_cv" v-if="employee.link_cv" target="_blank">
            Ver Link
          </a>
        </div>
      </div>
    </Fieldset>
    <div class="card p-mt-5">
      <div class="p-grid p-fluid p-field p-col-12 p-jc-center p-jc-sm-end">
        <div class="p-fluid p-col-fixed">
          <Button
              label="Guardar"
              icon="pi pi-check"
              class="p-button-raised p-button-success"
              @click="save"
              :disabled="loading"
          />
        </div>

        <div class="p-fluid p-col-fixed">
          <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-raised p-button-secondary p-button-text"
              @click="$router.push(employeeListRoute)"
              :disabled="loading"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>¡ATENCIÓN!</b></h5>
    <div class="card-body">
      <p class="card-title">
        El acceso a esta opción no se encuentra autorizado
      </p>
      <Button
          label="Regresar"
          class="p-button-danger p-mr-2"
          @click="$router.push('/admin')"
      />
    </div>
  </div>
</template>
<script src="./EmployeeCrud.js" lang="js"></script>
<style src="./EmployeeCrud.scss" lang="scss" scoped></style>
